import React, { useEffect, } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./HomePage.css";
import VisibilityIcon from "../assets/icone/visibilite.png";
import ImprovementIcon from "../assets/icone/productivite.png";
import MessageIcon from "../assets/icone/message.png";
import AnalyseIcone from "../assets/icone/analyse.png";
import FlecheIcone from "../assets/icone/fleche.png";
import PersoIcon from "../assets/icone/perso.png";
import TestIcone from "../assets/icone/test.png";
import StartIcone from "../assets/icone/start.png";
import Image1 from "../assets/service/classique.png";
import Image2 from "../assets/service/avance.png";
import Image3 from "../assets/service/premium.png";
import cle from "../assets/icone/design.png";

function Home() {
  

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <div className="accueil-container">
      
      <div className="content-container" data-aos="fade-down">
        <h1 className="titre">Agence Sunlight</h1>
        <p className="sous-titre">
          Lancer votre activité en ligne, peu importe votre avancement
        </p>
      </div>
      <div className="main-content">
        <div className="left-section" data-aos="fade-right">
          <p className="description">
            Bienvenue chez Agence Sunlight. Je suis passionné par la création de
            sites web professionnels et attrayants. Mon objectif est de vous
            aider à atteindre vos objectifs en ligne, que vous soyez une petite
            entreprise ou une grande société. Ensemble, nous pouvons transformer
            votre présence en ligne et maximiser votre potentiel de croissance.
          </p>
          <a href="https://calendly.com/loic-luttringer/decouvertes-de-mes-services">
            <button className="bouton">Réserver un rendez-vous</button>
          </a>
        </div>
        <div className="divider-vertical"></div>
        <div className="right-section" data-aos="fade-left">
          <div className="carte" data-aos="fade-up">
            <img src={VisibilityIcon} alt="Visibilité" />
            <h3>40%</h3>
            <p>
              des entreprises sans site web manquent des opportunités de
              visibilité.
            </p>
          </div>
          <div className="carte" data-aos="fade-up">
            <img src={ImprovementIcon} alt="Amélioration" />
            <h3>60%</h3>
            <p>
              de perte de croissance en productivité pour les entreprises sans
              présence en ligne.
            </p>
          </div>
          <div className="carte slide-in" data-aos="fade-up">
            <img src={MessageIcon} alt="Messages" />
            <h3>70%</h3>
            <p>
              des consommateurs ne font pas confiance aux entreprises sans site
              web.
            </p>
          </div>
          <p className="phrase-accrocheuse slide-in" data-aos="fade-up">
            Ne laissez pas votre entreprise dans l'ombre. Contactez-moi dès
            aujourd'hui et commencez à rayonner sur le web!
          </p>
        </div>
      </div>
      <div className="divider-horizontal"></div>
      <div className="extra-section" id="services-section">
        <h2 className="titre" data-aos="fade-up">
          Notre processus en 5 étapes
        </h2>
        <div className="extra-cards">
          <div className="extra-card" data-aos="fade-up">
            <img src={AnalyseIcone} alt="Analyse" />
            <h3>Analyse</h3>
            <p>Comprendre vos objectifs et besoins pour votre site web.</p>
          </div>
          <div className="extra-card" data-aos="fade-up">
            <img src={FlecheIcone} alt="Conception" />
            <h3>Conception</h3>
            <p>
              Créer des maquettes représentant le design et l'interface
              utilisateur.
            </p>
          </div>
          <div className="extra-card" data-aos="fade-up">
            <img src={PersoIcon} alt="Développement" />
            <h3>Développement</h3>
            <p>Coder le site web en utilisant les technologies appropriées.</p>
          </div>
          <div className="extra-card" data-aos="fade-up">
            <img src={TestIcone} alt="Tests" />
            <h3>Tests</h3>
            <p>
              Vérifier la fonctionnalité, l'accessibilité et la performance du
              site selon vos besoins.
            </p>
          </div>
          <div className="extra-card" data-aos="fade-up">
            <img src={StartIcone} alt="Lancement" />
            <h3>Lancement</h3>
            <p>Mise en ligne du site web et suivi de son bon fonctionnement.</p>
          </div>
        </div>
      </div>
      <div className="divider-horizontal"></div>
      <div className="rectangle" data-aos="fade-up">
        <div className="title">
          <img src={Image1} alt="Classique" />
          <h3>Classique</h3>
        </div>
        <div className="rectangle-content">
          <div className="details">
            <ul>
              <li>Création d'un site | Vitrine | </li>
              <li>1 à 4 pages</li>
              <li>Référencement naturel sur les moteurs de recherche</li>
            </ul>
          </div>
        </div>
        <div className="button-container">
          <a href="https://calendly.com/loic-luttringer/decouvertes-de-mes-services">
            <button>Réserver</button>
          </a>
        </div>
      </div>
      <div className="rectangle" data-aos="fade-up">
        <div className="title">
          <img src={Image2} alt="Avancé" />
          <h3>Avancé</h3>
        </div>
        <div className="rectangle-content">
          <div className="details">
            <ul>
              <li>Création d'un site | Vitrine | </li>
              <li>1 à 7 pages</li>
              <li>Référencement naturel sur les moteurs de recherche</li>
              <li>1 à 4 retouches par mois</li>
            </ul>
          </div>
        </div>
        <div className="button-container">
          <a href="https://calendly.com/loic-luttringer/decouvertes-de-mes-services">
            <button>Réserver</button>
          </a>
        </div>
      </div>
      <div className="rectangle" data-aos="fade-up">
        <div className="title">
          <img src={Image3} alt="Premium" />
          <h3>Premium</h3>
        </div>
        <div className="rectangle-content">
          <div className="details">
            <ul>
              <li>Création d'un site | Vitrine | E-Commerce |</li>
              <li>7 pages ou + </li>
              <li>Référencement naturel sur les moteurs de recherche</li>
              <li>7 retouches par mois</li>
            </ul>
          </div>
        </div>
        <div className="button-container">
          <a href="https://calendly.com/loic-luttringer/decouvertes-de-mes-services">
            <button>Réserver</button>
          </a>
        </div>
      </div>
      <div className="rectangle" data-aos="fade-up">
        <div className="title">
          <img src={cle} alt="Modifications" />
          <h3>Modifications</h3>
        </div>
        <div className="rectangle-content">
          <div className="details">
            <ul>
              <li>Création d'un site | Vitrine | E-Commerce |</li>
              <li>Créations de page ou de modules supplémentaire</li>
              <li>Référencement naturel sur les moteurs de recherche</li>
            </ul>
          </div>
        </div>
        <div className="button-container">
          <a href="https://calendly.com/loic-luttringer/decouvertes-de-mes-services">
            <button>Réserver</button>
          </a>
        </div>
      </div>
      <div className="divider-horizontal"></div>
    </div>
  );
}

export default Home;

import React, { useEffect, } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bike1 from "../assets/bike/Bike1.png"
import bike2 from "../assets/bike/Bike2.png"
import bike3 from "../assets/bike/Bike3.png"

import './BikeShopDemo.css'; // Import de la feuille de style

const BikeShopDemo = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <div className="bike-shop-demo">
      <header className="bike-shop-header">
        <p>
          Augmentez vos ventes en ligne grâce à un site web moderne et optimisé pour le SEO. Attirez plus de cyclistes 
          et présentez vos vélos et accessoires de manière professionnelle.
        </p>
      </header>

      <section className="bike-shop-section">
        <div className="bike-shop-content">
          <h2>Un site web dédié aux boutiques de vélos</h2>
          <p>
            Offrez une vitrine en ligne unique pour votre boutique de vélos. Présentez une large gamme de vélos 
            (VTT, vélos de route, électriques) avec des descriptions détaillées pour chaque modèle.
          </p>
        </div>
        <div className="bike-shop-image">
          <img src={bike1}alt="Vitrine de vélos en ligne" />
        </div>
      </section>

      <section className="bike-shop-section">
        <div className="bike-shop-content">
          <h2>Optimisation SEO pour votre boutique de vélos</h2>
          <p>
            Grâce à une optimisation SEO sur mesure, votre site sera visible par des clients potentiels recherchant 
            des vélos de qualité en ligne. Attirez plus de trafic organique et augmentez vos conversions.
          </p>
        </div>
        <div className="bike-shop-image">
          <img src={bike2} alt="Optimisation SEO boutique vélos" />
        </div>
      </section>

      <section className="bike-shop-section">
        <div className="bike-shop-content">
          <h2>Une expérience d'achat fluide et responsive</h2>
          <p>
            Offrez à vos clients une expérience d'achat fluide, que ce soit sur ordinateur, tablette ou smartphone. 
            Un site responsive et rapide garantit une navigation agréable et efficace pour tous.
          </p>
        </div>
        <div className="bike-shop-image">   
          <img src={bike3} alt="Site responsive vente de vélos" />
        </div>
      </section>
    </div>
  );
};

export default BikeShopDemo;

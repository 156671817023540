import React, { useEffect, } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Cave1 from '../assets/caviste/cave1.png'
import Cave2 from '../assets/caviste/cave2.png'
import './CavisteDemo.css'; // Import de la feuille de style

const CavisteDemo = () => {
useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="caviste-demo">
      <header className="caviste-header">
        <p>
          Créez un site moderne et optimisé pour attirer plus de clients. Mettez en avant votre cave à vin 
          avec une vitrine digitale performante.
        </p>
      </header>

      <section className="caviste-section">
        <div className="caviste-content">
          <h2>Un site vitrine optimisé pour les cavistes</h2>
          <p>
            Attirez les amateurs de vin en ligne grâce à un site conçu spécialement pour les cavistes. 
            Présentez vos vins, champagnes et spiritueux de manière élégante avec des descriptions attractives.
          </p>
        </div>
        <div className="caviste-image">
          <img src={Cave1} alt="Caviste en ligne" />
        </div>
      </section>

      <section className="caviste-section">
        <div className="caviste-content">
          <h2>Optimisation SEO pour une visibilité maximale</h2>
          <p>
            Profitez d'une stratégie de positionnement qui améliore le classement de votre site sur Google. Générer du trafic 
            organique est essentiel pour attirer plus de clients directement sur votre site.
          </p>
        </div>
        <div className="caviste-image">
          <img src={Cave2} alt="SEO pour cavistes" />
        </div>
      </section>
    </div>
  );
};

export default CavisteDemo;

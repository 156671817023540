import React, { useEffect, } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import sushi1 from "../assets/sushi/sushi1.png";
import sushi2 from "../assets/sushi/sushi2.png";
import sushi3 from "../assets/sushi/sushi3.png";

import './SushiRestaurantDemo.css'; // Import de la feuille de style


const SushiRestaurantDemo = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <div className="sushi-restaurant-demo">
      <header className="sushi-restaurant-header">
        <p>
          Offrez une expérience en ligne unique à vos clients. Grâce à un site web moderne et optimisé, mettez en avant 
          vos créations culinaires et facilitez les réservations en ligne.
        </p>
      </header>

      <section className="sushi-restaurant-section">
        <div className="sushi-restaurant-content">
          <h2>Présentez vos spécialités japonaises</h2>
          <p>
            Mettez en valeur vos sushis, makis, et sashimis avec de superbes photos et descriptions appétissantes. 
            Attirez les amateurs de cuisine japonaise en ligne en présentant votre carte de manière élégante.
          </p>
        </div>
        <div className="sushi-restaurant-image">
          <img src={sushi1} alt="Spécialités de sushi" />
        </div>
      </section>

      <section className="sushi-restaurant-section">
        <div className="sushi-restaurant-content">
          <h2>Optimisation SEO pour les restaurants de sushi</h2>
          <p>
            Faites en sorte que votre restaurant apparaisse en haut des résultats de recherche Google grâce à un 
            référencement SEO adapté. Attirez les clients qui recherchent des restaurants de sushi dans votre région.
          </p>
        </div>
        <div className="sushi-restaurant-image">
          <img src={sushi2} alt="SEO restaurant sushi" />
        </div>
      </section>

      <section className="sushi-restaurant-section">
        <div className="sushi-restaurant-content">
          <h2>Réservations et commandes en ligne faciles</h2>
          <p>
            Facilitez les réservations et les commandes en ligne directement depuis votre site. Offrez à vos clients 
            la possibilité de réserver une table ou de commander à emporter en quelques clics, depuis n'importe quel appareil.
          </p>
        </div>
        <div className="sushi-restaurant-image">
          <img src={sushi3} alt="Réservations en ligne sushi" />
        </div>
      </section>
    </div>
  );
};

export default SushiRestaurantDemo;

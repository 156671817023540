import React, { useEffect } from "react";
import "./ProjectsPage.css"; // Assurez-vous que le chemin vers votre fichier CSS est correct
import Project2Image from "../assets/img/TikAddict/portable.png";
import Caviste from "../assets/caviste/cave1.png"
import Bike from "../assets/bike/Bike1.png"
import Sushi from "../assets/sushi/sushi1.png"
import AOS from "aos";
import "aos/dist/aos.css";

const ProjectsPage = () => {
  const goToAddict = () => {
    window.open(
      "https://charlifeldschool.podia.com/",
      "_blank",
      "noopener,noreferrer"
    ); // Ouvre le lien externe dans un nouvel onglet
  };
  const goTocaviste = () => {
    window.location.href = "/caviste"; // Redirige vers la page Bloomy sur votre site
  };
  const goTobike = () => {
    window.location.href = "/bike"; // Redirige vers la page Bloomy sur votre site
  };
  const goTosushi = () => {
    window.location.href = "/sushi"; // Redirige vers la page Bloomy sur votre site
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
    });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="projects-container">
      <h1>Projets réalisés</h1>
      <div className="project-grid">
        {/* Deuxième carte */}
        <div className="project-card" data-aos="fade-down">
          <img src={Project2Image} alt="Projet 2" className="project-image" />
          <h2>TikTok Addict</h2>
          <p>WebDesign - Vitrine - Création</p>
          <button className="discover-btn" onClick={goToAddict}>
            Découvrir
          </button>
        </div>
        <div className="project-card" data-aos="fade-up">
          <img src={Caviste} alt="Bloomy" className="project-image" />
          <h2>Caviste exemple </h2>
          <p>Site internet entreprise</p>
          <button className="discover-btn" onClick={goTocaviste}>
            Découvrir
          </button>
        </div>
        <div className="project-card" data-aos="fade-up">
          <img src={Bike} alt="Bloomy" className="project-image" />
          <h2>Bike Shop exemple </h2>
          <p>Site internet velo store</p>
          <button className="discover-btn" onClick={goTobike}>
            Découvrir
          </button>
        </div>
        <div className="project-card" data-aos="fade-up">
          <img src={Sushi} alt="Bloomy" className="project-image" />
          <h2>Sushi store exemple </h2>
          <p>Site internet restaurant</p>
          <button className="discover-btn" onClick={goTosushi}>
            Découvrir
          </button>
        </div>
        {/* Ajoutez d'autres cartes de projet ici */}
      </div>
    </div>
  );
};

export default ProjectsPage;
